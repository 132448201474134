import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { FullStory, init as initFullStory } from '@fullstory/browser';
import { Suspense, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { SplashScreen } from '@/components/loading-screen';

import SessionTimeoutModal from './SessionTimeoutModal';

const AuthConsumer = ({ children }: { children: React.ReactNode }) => {
  const {
    error,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    user,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const navigate = useNavigate();

  const authenticate = useCallback(async () => {
    if (!isLoading && !user) {
      await loginWithRedirect();
    } else if (user) {
      const {
        name: displayName,
        email,
        orgTree,
      } = user['com.xpanse.starter.security.domain.UserProfile'];

      const fullstoryOrgId = import.meta.env.VITE_FULLSTORY_ORG_ID;

      if (fullstoryOrgId) {
        initFullStory({ orgId: fullstoryOrgId });

        FullStory('setIdentity', {
          // omit 'auth0|'
          uid: user.sub?.split('|')[1],
          properties: {
            displayName,
            email,
            org: orgTree.name,
          },
        });
      }
    }
  }, [isLoading, loginWithRedirect, user]);

  useEffect(() => {
    if (!error && error === 'user_in_denied_list') {
      navigate('/forbidden');
    } else {
      authenticate();
    }
  }, [authenticate, error, isAuthenticated, navigate]);

  return (
    <>
      <SessionTimeoutModal
        sessionDuration={14400}
        showModalAt={120}
        onExtendSession={getAccessTokenSilently}
        onLogout={() => logout({ logoutParams: { returnTo: window.location.origin } })}
      />
      {isLoading && <SplashScreen />}
      {isAuthenticated && <>{children}</>}
    </>
  );
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => (
  <Auth0Provider
    domain={import.meta.env.VITE_AUTH0_DOMAIN}
    cacheLocation="localstorage"
    clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
  >
    <Suspense fallback={<SplashScreen />}>
      <AuthConsumer>{children}</AuthConsumer>
    </Suspense>
  </Auth0Provider>
);
