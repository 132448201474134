import { matchPath, useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { alpha, Theme } from '@mui/material/styles';

import Logo from '@/components/logo/Logo';
import RouterLink from '@/components/RouterLink';
import { NAV } from '@/constants';
import { useNavData } from '@/layout/navigation/useNavData';
import { hideScroll } from '@/theme/css';

import NavToggleButton from './NavToggleButton';

export default function NavMini() {
  const navData = useNavData();
  const { pathname } = useLocation();
  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScroll.x,
        }}
      >
        <Box
          sx={{
            pl: 3,
            py: 2.5,
          }}
        >
          <Logo small image="/assets/nvs-logo-sm.png" />
        </Box>

        <List>
          {navData.map((navSection) =>
            navSection.items.map((navItem, index) => {
              const isActive = matchPath(navItem.path, pathname) !== null;

              const activeColor = (theme: Theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.primary.main
                  : theme.palette.primary.light;

              return (
                <Box key={index} sx={{ m: 1 }}>
                  <Link component={RouterLink} href={navItem.path} color="inherit" underline="none">
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={(theme) => ({
                          flexDirection: 'column',
                          borderRadius: 1,
                          color: isActive ? activeColor(theme) : 'inherit',
                          backgroundColor: isActive
                            ? alpha(theme.palette.primary.main, 0.08)
                            : 'transparent',
                          '&:hover': {
                            backgroundColor: isActive
                              ? alpha(theme.palette.primary.main, 0.16)
                              : theme.palette.action.hover,
                          },
                        })}
                      >
                        <ListItemIcon
                          sx={{
                            color: isActive ? 'primary' : 'text.secondary',
                          }}
                        >
                          <Box
                            sx={{
                              width: 24,
                              ml: 1.5,
                            }}
                          >
                            {navItem.icon}
                          </Box>
                        </ListItemIcon>

                        <ListItemText
                          primaryTypographyProps={{
                            align: 'center',
                            variant: 'body2',
                            fontWeight: 500,
                            color: isActive ? 'primary' : 'text.secondary',
                          }}
                        >
                          {navItem.title}
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </Link>
                </Box>
              );
            })
          )}
        </List>
      </Stack>
    </Box>
  );
}
